import Player from "@vimeo/player";

const btnPlayClass = "is-playing";

const playVideo = (player, $btnPlay) => {
  player.play().then(function () {
    $btnPlay.classList.add(btnPlayClass);
    $btnPlay.setAttribute("aria-label", "Pause");
    $btnPlay.setAttribute("aria-pressed", true);
  });
};

const pauseVideo = (player, $btnPlay) => {
  player.pause().then(function () {
    $btnPlay.classList.remove(btnPlayClass);
    $btnPlay.setAttribute("aria-label", "Play");
    $btnPlay.setAttribute("aria-pressed", false);
  });
};

const goToStart = (player, $btnPlay) => {
  player
    .setCurrentTime(0)
    .then(function () {
      $btnPlay.classList.remove(btnPlayClass);
      $btnPlay.setAttribute("aria-label", "Play");
      $btnPlay.setAttribute("aria-pressed", false);
    })
    .catch(function (error) {
      switch (error.name) {
        case "RangeError":
          // The time is less than 0 or greater than the video's duration
          break;
        default:
          // Some other error occurred
          break;
      }
    });
};

const Vimeo = () => {
  const videoContainers = document.querySelectorAll(".video-container");

  if (videoContainers) {
    videoContainers.forEach(function (videoSection) {
      const $iframe = videoSection.querySelector("iframe");
      const $btnPlay = videoSection.querySelector(".btn-play");

      const player = new Player($iframe);

      videoSection.style.setProperty(
        "--aspect-ratio",
        `${player.element.width} / ${player.element.height}`
      );

      $btnPlay.addEventListener("click", function () {
        if ($btnPlay.classList.contains(btnPlayClass)) {
          pauseVideo(player, $btnPlay);
        } else {
          playVideo(player, $btnPlay);
        }
      });

      player.on("ended", function () {
        goToStart(player, $btnPlay);
      });
    });
  }
};

export default Vimeo;
